export default class SidePanels {

    constructor(node, index) {
        this.node = node;
        this.index = index;
        this.target = this.node.dataset.target;
        this.panel = document.querySelector(this.target);

        this.close = this.panel.querySelector('.side-panel-close');

        this.clickOpen = this.clickOpen.bind(this);
        this.clickClose = this.clickClose.bind(this);

        this.init();
    }

    init() {
        this.reset();
        this.addListeners();
    }

    addListeners() {
        this.node.addEventListener('click', this.clickOpen);
        this.close.addEventListener('click', this.clickClose);
    }

    clickOpen(e) {
        e.preventDefault();
        this.panel.classList.add('open');
    }

    clickClose(e) {
        e.preventDefault();
        this.panel.classList.remove('open');
    }

    reset() {
        this.panel.classList.remove('open');
    }


    destroy() {
        this.node.removeEventListener('click', this.clickOpen);
        this.close.removeEventListener('click', this.clickClose);
        this.reset();
    }

    static resetAll() {
        setTimeout(() => {
            SidePanels.items.forEach(elem => {
                elem.reset();
            });
        }, 500);
    }

    static destroyAll() {
        SidePanels.items.forEach(elem => {
            elem.destroy();
        });
    }

    static init() {
        SidePanels.items = Array.from(document.querySelectorAll('.side-panel-open')).map((node, index) => new SidePanels(node, index));
    }
}