export default class AnimatedFields {

    constructor(node, index) {
        this.node = node;
        this.index = index;
        this.input = this.node.querySelector('.form-field');

        this.clickInput = this.clickInput.bind(this);

        this.init();
    }

    init() {
        this.reset();
        this.addListeners();
    }

    addListeners() {
        this.node.addEventListener('click', this.clickInput);
    }

    clickInput(e) {
        this.node.classList.add('active');

        var node = this.node;
        var input = this.input;

        document.addEventListener('click', function(e) {

            var isClickInside = node.contains(e.target);
          
            if (!isClickInside && input.value === "") {
                node.classList.remove('active');
            }
        });
    }

    reset() {
        this.node.classList.remove('active');
    }


    destroy() {
        this.node.removeEventListener('click', this.clickInput);
        this.reset();
    }

    static resetAll() {
        setTimeout(() => {
            AnimatedFields.items.forEach(elem => {
                elem.reset();
            });
        }, 500);
    }

    static destroyAll() {
        AnimatedFields.items.forEach(elem => {
            elem.destroy();
        });
    }

    static init() {
        AnimatedFields.items = Array.from(document.querySelectorAll('.form-item-animated')).map((node, index) => new AnimatedFields(node, index));
    }
}