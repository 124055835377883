export default class Accordeons {

    constructor(node, index) {
        this.node = node;
        this.index = index;
        
        this.title = this.node.querySelector('.accordeon-title');
        this.body = this.node.querySelector('.accordeon-body');

        this.clickAction = this.clickAction.bind(this);

        this.init();
    }

    init() {
        this.reset();
        this.addListeners();
    }

    addListeners() {
        this.title.addEventListener('click', this.clickAction);
    }

    clickAction(e) {
        this.node.classList.toggle('open');
    }

    reset() {
        this.node.classList.remove('open');
    }


    destroy() {
        this.title.removeEventListener('click', this.clickAction);
        this.reset();
    }

    static resetAll() {
        setTimeout(() => {
            Accordeons.items.forEach(elem => {
                elem.reset();
            });
        }, 500);
    }

    static destroyAll() {
        Accordeons.items.forEach(elem => {
            elem.destroy();
        });
    }

    static init() {
        Accordeons.items = Array.from(document.querySelectorAll('.accordeon')).map((node, index) => new Accordeons(node, index));
    }
}