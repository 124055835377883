export const GLOBE_RADIUS = 0.6;
export const GLOBE_MINIMUM_SPEED_ROTATION_X = 0.0000;
export const GLOBE_MINIMUM_SPEED_ROTATION_Y = 0.0005;
export const PARTICLE_RADIUS = GLOBE_RADIUS + 0.001;
export const PARTICLE_SIZE = 0.04;
export const PARTICLE_SIZE_HOVER = 0.06;
export const PARTICLE_THRESHOLD = 0.015;
export const GLOBE_CENTER = new THREE.Vector3(0, 0, 0);
export const CURVE_SEGMENTS = 128;
export const CURVE_MIN_ALTITUDE = GLOBE_RADIUS / 10;
export const CURVE_MAX_ALTITUDE = GLOBE_RADIUS / 2;
export const DEGREE_TO_RADIAN = Math.PI / 180;
export const PI_TWO = Math.PI * 2;
export const PI_HALF = Math.PI / 2;
