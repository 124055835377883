export default class Shares {

    constructor(node, index) {
        this.node = node;
        this.index = index;

        this.clickAction = this.clickAction.bind(this);

        this.init();
    }

    init() {
        this.reset();
        this.addListeners();
    }

    addListeners() {
        this.node.addEventListener('click', this.clickAction);
    }

    clickAction(e) {
        this.node.classList.add('open');

        var node = this.node;

        document.addEventListener('click', function(e) {
            var isClickInside = node.contains(e.target);
          
            if (!isClickInside) {
                node.classList.remove('open');
            }
        });
    }

    reset() {
        this.node.classList.remove('open');
    }


    destroy() {
        this.title.removeEventListener('click', this.clickAction);
        this.reset();
    }

    static resetAll() {
        setTimeout(() => {
            Shares.items.forEach(elem => {
                elem.reset();
            });
        }, 500);
    }

    static destroyAll() {
        Shares.items.forEach(elem => {
            elem.destroy();
        });
    }

    static init() {
        Shares.items = Array.from(document.querySelectorAll('.share')).map((node, index) => new Shares(node, index));
    }
}