export default class Menu {

    constructor(node) {
        this.node = node;

        this.ctaOpen = this.node.querySelector('#main-menu-burger');
        this.ctaClose = this.node.querySelector('#menu-mobile-close');
        this.menuWrapper = this.node.querySelector('.main-menu-mobile-wrapper');
        this.level1 = Array.from(this.node.querySelectorAll('.first-level.has-submenu > .menu-link'));
        this.back = Array.from(this.node.querySelectorAll('.submenu-back')); 

        this.clickAction = this.clickAction.bind(this);
        this.clickLevel1 = this.clickLevel1.bind(this);
        this.clickBack = this.clickBack.bind(this);

        this.init();
    }

    init() {
        this.reset();
        this.addListeners();
    }

    addListeners() {

        this.ctaOpen.addEventListener('click', this.clickAction);
        this.ctaClose.addEventListener('click', this.clickAction);

        this.level1.forEach(l1 => {
            l1.addEventListener('click', this.clickLevel1);
        });

        this.back.forEach(b => {
            b.addEventListener('click', this.clickBack);
        });

    }

    clickAction(e) {
        e.preventDefault();

        

        if(e.target.classList.contains('menu-open')) {
            // document.querySelector('body').classList.add('h-noscroll');
            this.menuWrapper.classList.add('open');
            
        } else if(e.target.classList.contains('menu-close')) {
            this.menuWrapper.classList.remove('open');
            // document.querySelector('body').classList.remove('h-noscroll');
        }
    }

    clickLevel1(e) {
        e.preventDefault();
        e.target.nextElementSibling.classList.add('open');
    }

    clickBack(e) {
        e.preventDefault();
        e.target.closest('.submenu-wrapper').classList.remove('open');
    }

    reset() {
        this.menuWrapper.classList.remove('open');
    }


    static destroy() {

        this.ctaOpen.removeEventListener('click', this.clickAction);
        this.ctaClose.removeEventListener('click', this.clickAction);

        this.level1.forEach(l1 => {
            l1.removeEventListener('click', this.clickLevel1);
        });

        this.clickBack.forEach(b => {
            b.removeEventListener('click', this.clickBack);
        });

        this.reset();
    }

    static init() {
        Menu.item = new Menu(document.querySelector('.header'));
    }
}