import Accordeons from './partials/accordeons';
import AnimatedFields from './partials/animatedFields';
import Animations from './partials/animations';
import EmbeddedVideos from './partials/embeddedVideos';
import { Globe } from './partials/globe/globe';
import Menu from './partials/menu';
import ProcessSlider from './partials/processSlider';
import Shares from './partials/shares';
import Sliders from './partials/sliders';
import SidePanels from './partials/sidePanels';
import Tabs from './partials/tabs';
import Gmap from './partials/gmap/gmap';

// Debug helpers
const debug = true;

window.debug__ = (...args) => {
	if (debug) {
		console.log(...args);
	}
};

export default class Main {
    
	constructor() {
		this.init();
	}

	init() {
		Accordeons.init();
		AnimatedFields.init();
		Animations.init();
        EmbeddedVideos.init();
        Menu.init();
		Globe.init();
		Shares.init();
		SidePanels.init();
		Sliders.init();
		ProcessSlider.init();
		Tabs.init();
		if (document.getElementsByClassName('scroll-down')[0]) {
			document.getElementsByClassName('scroll-down')[0].addEventListener("click", function () {
				document.getElementById('section--from-laboratory').scrollIntoView();
			});
		}
	}

}

window.initMap = () => {
	const gmap = document.querySelector('.map__gmap');
	if (gmap)
		window.globeMap = new Gmap(gmap);
};

const main = new Main();
