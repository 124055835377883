export default class Tabs {

    constructor(node, index) {
        this.node = node;
        this.index = index;
        this.controls = Array.from(node.querySelectorAll('[data-tabs-panel-control]'));
        this.contents = Array.from(node.querySelectorAll('[data-tabs-panel-wrapper]'));
        this.wrapper = this.node.querySelector('.tabs__content');

        this.clickTab = this.clickTab.bind(this);
        this.reset();
        this.addListeners();
    }

    reset() {
        this.controls[0].classList.add('active');
        this.contents[0].classList.add('active');
    }

    addListeners() {
        this.controls.forEach(control => {
            control.addEventListener('click', this.clickTab);
        });
    }

    clickTab(e) {
        if (!e.target.classList.contains('active')) {
            const tabId = e.target.getAttribute('data-tabs-panel-control');
            const result = this.contents.filter(x => {
                return x.getAttribute('data-tabs-panel-wrapper') === tabId;
            });
            this.controls.forEach(x => x.classList.remove('active'));
            this.contents.forEach(x => x.classList.remove('active'));
            e.target.classList.add('active');
            result[0].classList.add('active');
        }
    }

    destroy() {
        this.controls.forEach(control => {
            control.removeEventListener('click', this.clickTab);
            control.classList.remove('active');
        });
        this.contents.forEach(x => x.classList.remove('active'));
    }

    static resetAll() {
        setTimeout(() => {
            Tabs.items.forEach(elem => {
                elem.reset();
            });
        }, 500);
    }

    static destroyAll() {
        Tabs.items.forEach(elem => {
            elem.destroy();
        });
    }

    static init() {
        Tabs.items = Array.from(document.querySelectorAll('[data-tabs-panel]')).map((node, index) => new Tabs(node, index));
        Tabs.resetAll();
    }
}