import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class Animations {
    
    static init() {

        window.addEventListener('load', function(event) {
            var bodyHeight = document.querySelector('body').offsetHeight;      
            var header = document.querySelector('.header');

            /* *********** */
            /* STICKY MENU */
            /* *********** */

            window.addEventListener('scroll', function() {

                let scroll = window.scrollY;

                if (scroll > 2) {
                    header.classList.add('header--negative')
                } else {
                    header.classList.remove('header--negative')
                }

			});		

        });

		

        // COUNTERS - OUR NUMBERS

        const counters = document.querySelectorAll('.animated-number');
        const speed = 200;

        ScrollTrigger.create({
            trigger: '.section--our-numbers',
            start: "top 70%",
            end: "bottom 90%",
            markers: false,
            toggleActions: "play none none none",
            onEnter: animateNumbers
        });

        function animateNumbers() {

            counters.forEach( counter => {

                const animate = () => {
    
                    const value = +counter.dataset.target;
                    const data = +counter.innerText;
                    
                    const time = value / speed;
    
                    if(data < value) {
                        counter.innerText = Math.ceil(data + time);
                        setTimeout(animate, 1);
                    } else {
                        counter.innerText = value;
                    }
                    
                }
                
                animate();
            });
        }

		
    }
}
