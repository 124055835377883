import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

export default class ProcessSlider {
    
    static init() {

        // check if the component exists
        if(!document.querySelector('.swiper--process')) {
            return;
        }

        // retrieving inistanciated swiper
        const swiper = document.querySelector('.swiper--process').swiper;
        
        // viewport sizes
		const vw = window.innerWidth;
		const vh = window.innerHeight;

		// mouse position (related to the viewport)
		let mousePosition = {x: 0, y: 0};

		// get process section
		const processSection = document.querySelector('.section--process');
		const cursor = processSection.querySelector('.cursor');	

        let direction = 1;
        let active = false;

        const tick = (event) => {

            // get the right direction
            direction = mousePosition.x > vw / 2 ? 1 : -1;

            const y = mousePosition.y - processSection.getBoundingClientRect().top

            // update mouse pointer
            if(direction > 0) {
                cursor.style.transform = `translateX(${mousePosition.x}px) translateY(${y}px) rotate(180deg)`
            } else {
                cursor.style.transform = `translateX(${mousePosition.x}px) translateY(${y}px) rotate(0deg)`
            }
            
            // check recurrent call
            if(active) {
                window.requestAnimationFrame(tick);
            }
            
        }

        // Activate special arrows
        processSection.addEventListener("mouseenter", (event) => {
            active = true;
            window.requestAnimationFrame(tick);
        })

        // Deactivate special arrows
        processSection.addEventListener("mouseleave", (event) => {
            active = false;
            window.requestAnimationFrame(tick);
        })
        
        // Update current position
		processSection.addEventListener("mousemove", (event) => {
			mousePosition.x = event.clientX;
			mousePosition.y = event.clientY;
		})

        // Scroll slide when clicking
        processSection.addEventListener("click", (event) => {

            if(direction < 0) {
                swiper.slidePrev()
            } else {
                swiper.slideNext()
            }
        })  

    }
}