
import { gmapStyles, gmapCluster } from './gmap.config';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default class Gmap
{
	logEnabled = true;
	gmapLocations = [];
	map = null;
	markers = [];
	cluster = null;
	infoWindow = null;

	constructor(node)
	{
		this.node = node;
		this.styles = gmapStyles;
		var t = this;
		fetch("/api/presence/locations?state=1",
			{
				method: 'GET',
				cache: 'no-store'
			})
			.then((resp) => resp.json())
			.then(function (data)
			{
				if (data.success)
				{
					t.gmapLocations = data.gmapLocations;
					t.setupGmap();
				}
				else
					t.gmapLog("Gmap: " + data.message);
			})
			.catch(error => { alert(error); });
		t.gmapLog("Gmap: ctor");
	}

	setupGmap()
	{
		if (this.gmapLocations.length > 0)
		{
			this.gmapLog("Gmap: setup ...");
			this.initMap();
			this.setMarkers();
			this.setClusters();
			this.setInfo();
			this.setFilters();
			this.gmapLog("Gmap: OK");
			/*const t = this;
			setTimeout(function ()
			{
				t.setCenterToUser();
			}, 100);*/
		}
		else
			this.gmapLog("Gmap: no locations");
	}


	initMap()
	{
		const defaultLocation = {
			lat: this.gmapLocations[0].lat,
			lng: this.gmapLocations[0].lng
		};

		const map = new google.maps.Map(this.node, { zoom: 2, center: defaultLocation, });
		map.setOptions({ styles: this.styles });
		this.map = map;
		this.gmapLog("	initMap");
	}

	setMarkers()
	{
		let markers = [];
		this.gmapLocations.forEach((location) =>
		{
			const marker = new google.maps.Marker({
				position: { lat: location.lat, lng: location.lng },
				map: this.map,
				icon: {
					url: location.icon,
					scaledSize: new google.maps.Size(30, 30),
				},
				infoWindowMarkup: location.infoWindowMarkup,
				id: location.utId,
				type: location.type
			});
			markers.push(marker);
		});
		this.markers = markers;
		this.gmapLog("	setMarkers");
	}

	setClusters()
	{
		if (this.cluster !== null)
			this.cluster.clearMarkers();

		const renderer = {
			render: ({ count, position }) =>
				new google.maps.Marker({
					label: {
						text: String(count),
						color: "#8F8F8F",
						fontSize: "10px",
					},
					position,
					zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
					icon: {
						url: gmapCluster,
						scaledSize: new google.maps.Size(30, 30),
					}
				}),
		};

		var t = this;
		const markerCluster = new MarkerClusterer({
			map: this.map,
			markers: this.markers.filter((m) => { return m.getVisible(); }),
			renderer: renderer,
			onClusterClick: function (e, cluster, map)
			{
				if (t.infoWindow != null) t.infoWindow.close();
				map.fitBounds(cluster.bounds);
			}
		});
		this.cluster = markerCluster;
		this.gmapLog("	setClusters");
	}

	setInfo()
	{
		const infoWindow = new google.maps.InfoWindow({
			content: "",
		});

		this.markers.forEach((marker) =>
		{
			marker.addListener("click", () =>
			{
				infoWindow.close();
				infoWindow.setContent(marker.infoWindowMarkup);
				infoWindow.open({
					anchor: marker,
					map: this.map,
					shouldFocus: false,
				});
			});
		});

		this.infoWindow = infoWindow;
		this.gmapLog("	setInfo");
	}

	setCenterToUser()
	{
		var t = this;
		if (navigator.geolocation)
			navigator.geolocation.getCurrentPosition(function (position)
			{
				var latitude = position.coords.latitude;
				var longitude = position.coords.longitude;
				var center = new google.maps.LatLng(latitude, longitude);
				if (t.map != null)
					t.map.setCenter(center);
				t.gmapLog("Gmap: setCenterToUser (OK)");
			}, function (err)
			{
				t.gmapLog("Gmap: setCenterToUser ('" + err.message + "')");
			});
	}

	setFilters()
	{
		const t = this;
		$(".map__categories .map__item").on("click", function (e)
		{
			var $t = $(e.target);
			$(".map__categories .map__item.active").removeClass("active");
			$t.addClass("active");

			var filter = parseInt($t.attr("data-filter-type"));
			t.filterMarkers({ type: filter });
		});
		t.gmapLog("	setFilters");
	}

	filterMarkers(filters)
	{
		this.gmapLog("Gmap: filters ");
		this.gmapLog(filters);

		this.gmapLog("	visibility");
		this.markers.forEach((marker) =>
		{
			let visible = filters.type === -1 ? true : marker.type === filters.type;
			marker.setVisible(visible);
		});

		this.gmapLog("	bounds");
		let bounds = new google.maps.LatLngBounds();
		this.markers.filter((m) => { return m.getVisible(); }).forEach((marker) =>
		{
			bounds.extend(marker.getPosition());
		});
		this.map.fitBounds(bounds);

		this.setClusters();
	}

	gmapLog(data)
	{
		if (this.logEnabled)
			try
			{
				console.log(data);
			} catch (e)
			{
			}			
	}
}
