import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

export default class Sliders {

    constructor(slider, index) {
        this.slider = slider;
        this.index = index;
        this.slides = Array.from(this.slider.querySelectorAll('.swiper-slide'));
        this.arrows = this.slider.querySelector('.swiper-arrows');

        this.options = this.setOptions();
        this.initSlider();
        this.supportOperations();
        
    }

    setOptions() {

        let options = {};

        if(this.slider.classList.contains('swiper--elements')) {
            options = {
                centeredSlides: false,
                grabCursor: false,
                keyboardControl: true,
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
                slidesPerView: 1,
                spaceBetween: 20,
                watchOverflow: true,
                breakpoints: {
                    1024: {
                        navigation: {
                            nextEl: '.swiper-button-elements-next',
                            prevEl: '.swiper-button-elements-prev',
                        },
                        slidesPerView: "auto",
                        spaceBetween: 20
                    }
                }
            }
        } else if (this.slider.classList.contains('swiper--hero')) {
            options = {
                autoplay: false,
                direction: "vertical",
                grabCursor: true,
                keyboardControl: true,
                mousewheel: {
                    releaseOnEdges: false,
                    sensitivity: 0.5,
                    thresholdDelta: 20,
                    thresholdTime: 500
                },
                pagination: {
                    clickable: 'true',
                    el: '.swiper-pagination',
                    type: 'bullets',
                  },
                paginationClickable: true,
                slidesPerView: 1,
                on: {
                    reachEnd: function (swiper) {
                      
                      setTimeout(() => {
                        swiper.mousewheel.disable();
                      }, 500);

                    },
                },
            }
        } else if(this.slider.classList.contains('swiper--targets')) {

            let loopDesktop = true;
            let loopMobile = true;

            if(this.slides.length <= 2) {
                loopDesktop = false;
            }

            if(this.slides.length == 1) {
                loopMobile = false;
            }

            options = {
                autoplay: {
                    delay: 5000,
                },
                centeredSlides: true,
                grabCursor: false,
                keyboardControl: true,
                loop: loopMobile,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
                slidesPerView: 1,
                spaceBetween: 50,
                watchOverflow: true,
                breakpoints: {
                    768: {
                        autoplay: {
                            delay: 5000,
                        },
                        centeredSlides: false,
                        grabCursor: false,
                        keyboardControl: true,
                        loop: loopDesktop,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        slidesPerView: 2,
                        spaceBetween: 20,
                        watchOverflow: true,
                    },
                    1024: {
                        autoplay: {
                            delay: 5000,
                        },
                        centeredSlides: false,
                        grabCursor: false,
                        keyboardControl: true,
                        loop: loopDesktop,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        slidesPerView: 2,
                        spaceBetween: 20,
                        watchOverflow: true,
                    }
                }
            }
        } else if(this.slider.classList.contains('swiper--crops')) {

            let loopDesktop = true;
            let loopMobile = true;

            if(this.slides.length <= 2) {
                loopDesktop = false;
            }

            if(this.slides.length == 1) {
                loopMobile = false;
            }

            options = {
                autoplay: {
                    delay: 5000,
                },
                centeredSlides: false,
                grabCursor: false,
                keyboardControl: true,
                loop: loopMobile,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                },
                slidesPerView: 2,
                spaceBetween: 10,
                watchOverflow: true,
                breakpoints: {
                    768: {
                        autoplay: {
                            delay: 5000,
                        },
                        centeredSlides: false,
                        grabCursor: false,
                        keyboardControl: true,
                        loop: loopDesktop,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        slidesPerView: 2,
                        spaceBetween: 20,
                        watchOverflow: true,
                    },
                    1024: {
                        autoplay: {
                            delay: 5000,
                        },
                        centeredSlides: false,
                        grabCursor: false,
                        keyboardControl: true,
                        loop: loopDesktop,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        slidesPerView: "auto",
                        spaceBetween: 20,
                        watchOverflow: true,
                    }
                }
            }
        } else if (this.slider.classList.contains('swiper--testimonials')) {
            options = {
                grabCursor: false,
                keyboardControl: true,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                    renderFraction: function (currentClass, totalClass) {
                        return 'Interview <span class="' + currentClass + '"></span>' +
                               ' of ' +
                               '<span class="' + totalClass + '"></span>';
                    }
                },
                slidesPerView: 1,
                spaceBetween: 10,
                watchOverflow: true
            }
        } else if (this.slider.classList.contains('swiper--magazine')) {
            options = {
                grabCursor: false,
                keyboardControl: true,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                slidesPerView: 1,
                watchOverflow: true
            }
        } else if (this.slider.classList.contains('swiper--foundation')) {
            options = {
                grabCursor: false,
                keyboardControl: true,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-foundation.swiper-button-next',
                    prevEl: '.swiper-button-foundation.swiper-button-prev',
                },
                slidesPerView: 1,
                watchOverflow: true
            }
        } 
        else if (this.slider.classList.contains('swiper--process')) {
            options = {
                grabCursor: false,
                keyboardControl: true,
                loop: true,
                slidesPerView: "auto",
                watchOverflow: true
            }
        } 
        else if (this.slider.classList.contains('swiper--map')) {

            let labels = [];

            for(let slide of this.slider.querySelectorAll('.swiper-slide')) {
                labels.push(slide.dataset.pagination)
            }

            options = {
                grabCursor: false,
                keyboardControl: true,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                slidesPerView: 1,
                watchOverflow: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                      return '<span class="' + className + '"><span class="year">' + labels[index] + "</span></span>";
                    },
                  },
            }
        } else if (this.slider.classList.contains('swiper--timeline')) {

            let labels = [];

            for(let slide of this.slider.querySelectorAll('.swiper-slide')) {
                labels.push(slide.dataset.pagination)
            }

            options = {
                grabCursor: false,
                keyboardControl: true,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                slidesPerView: 1,
                watchOverflow: true,
                pagination: {
                    el: ".swiper-pagination.swiper-pagination--side, .swiper-pagination.swiper-pagination--top",
                    clickable: true,
                    renderBullet: function (index, className) {
                      return '<span class="' + className + '"><span class="year">' + labels[index] + "</span></span>";
                    },
                  },
            }

        }

        return options;  
    }

    supportOperations() {

        if(this.slider.classList.contains('swiper--targets')) {

            // Remove arrows for less than two elements
            if(this.slides.length <= 2) {
                this.arrows.classList.add('h-hide');
            }

        } else if(this.slider.classList.contains('swiper--crops')) {

            // Remove arrows for less than two elements
            if(this.slides.length <= 2) {
                this.arrows.classList.add('h-hide');
            }

        }
    }

    initSlider() {
        let swiperInstance = new Swiper(this.slider, this.options);
        swiperInstance.init();
        this.swiperInstance = swiperInstance;
    }

    destroy() {
        this.swiperInstance.destroy();
    }

    static init() {
        Sliders.items = Array.from(document.querySelectorAll('.swiper-container')).map((slider, index) => new Sliders(slider, index));
    }

    static destroyAll() {
        Sliders.items.forEach(slider => {
            slider.destroy();
        });
    }
}