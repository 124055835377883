export default class EmbeddedVideos {

	constructor(node, index) {
		this.node = node;
		this.index = index;

		this.preview = this.node.querySelector('.preview');
		this.iframe = this.node.querySelector('iframe');
		this.iframePlaceholder = this.node.querySelector('.iframePlaceholder');
		this.playVideo = null;
		this.stopVideo = null;

		this.clickAction = this.clickAction.bind(this);

		this.init();
	}

	init() {

		// Creates an <iframe> (and YouTube player)
		// after the API code downloads.
		var iframePlaceholder = this.iframePlaceholder;

		var player;

		function onYouTubeIframeAPIReady() {
			player = new YT.Player(iframePlaceholder.id, {
				height: '360',
				width: '640',
				videoId: iframePlaceholder.dataset.video,
			});
		}

		function stopVideo() {
			player.stopVideo();
		}

		function playVideo() {
			player.playVideo();
		}

		onYouTubeIframeAPIReady();

		this.playVideo = playVideo;
		this.stopVideo = stopVideo;

		this.reset();
		this.addListeners();
	}

	addListeners() {
		this.preview.addEventListener('click', this.clickAction);
	}

	clickAction(e) {
		e.preventDefault();
		this.preview.classList.add('h-hide');

		this.playVideo();
	}


	reset() {
		this.preview.classList.remove('h-hide');
	}


	destroy() {
		this.preview.removeEventListener('click', this.clickAction);
		this.reset();
	}

	static resetAll() {
		setTimeout(() => {
			EmbeddedVideos.items.forEach(elem => {
				elem.reset();
			});
		}, 500);
	}

	static destroyAll() {
		EmbeddedVideos.items.forEach(elem => {
			elem.destroy();
		});

		// Insert here remove script
	}

	static init() {

		// Loading the IFrame Player API code asynchronously.
		var tag = document.createElement('script');

		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		tag.onload = function () {
			window.YT.ready(function () {
				EmbeddedVideos.items = Array.from(document.querySelectorAll('.embedded-video-wrapper')).map((node, index) => new EmbeddedVideos(node, index));
			});
		};
	}
}
